<template>
  <div class="">
    <div
      v-for="(item, i) in footerLinks"
      :key="item.heading"
      class="footer__item"
    >
      <p
        class="footer__item-heading i-arrow-drop-grey"
        onclick="this.parentElement.classList.toggle('open')"
      >
        {{ item.heading }}
      </p>
      <ul
        class="footer__link-group"
      >
        <li
          v-for="link in item.links.slice(0, 5)"
          :key="link.title"
          class="footer__link"
        >
          <ALink
            :class="link.class"
            :href="getLink(link)"
          >
            {{ link.title }}
            <ABadge
              v-if="link.badge"
              border="xs"
              size="xs"
            >
              {{ link.badge }}
            </ABadge>
          </ALink>
        </li>
        <li v-if="item.links.length > 5" class="collapsed">
          <ul>
            <li
              v-for="link in item.links.slice(5)"
              :key="link.title"
              class="footer__link"
            >
              <ALink
                :class="link.class"
                :href="getLink(link)"
              >
                {{ link.title }}
                <ABadge
                  v-if="link.badge"
                  border="xs"
                  size="xs"
                >
                  {{ link.badge }}
                </ABadge>
              </ALink>
            </li>
          </ul>
        </li>

        <AActionButton
          v-if="btnMore[i] > 5"
          class="footer__btn-more"
          onclick="this.parentElement.classList.toggle('open')"
        >
          <p>{{ $t('organisms.footer.more') }}</p>
          <span>{{ $t('organisms.footer.hide') }}</span>
        </AActionButton>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ALink from '@/components/atoms/Link/ALink.vue'
import ABadge from '@/components/atoms/Badge/ABadge.vue'
import AActionButton from '@/components/atoms/ActionButton/AActionButton.vue'
import { useFooter } from '@/composables/footer'
import { useNavigate } from '@/composables/useNavigate'
import { useConfig } from '@/stores/config'
import type { FooterLink } from '@/composables/footer'

defineComponent({ name: 'GlobalFooterLinks' })

const { getUrl } = useNavigate()
const { isMscRegion } = useConfig()
const { getFooterLinks } = useFooter()

const footerLinks = await getFooterLinks(isMscRegion.value)
const btnMore = footerLinks.map((el: any) => el.links.length)

const getLink = (link: FooterLink): string | undefined => {
  if (link.isFullPath) {
    return link.href ? link.href : undefined
  }

  return link.href ? getUrl(link.href, !!link.isEndWithSlash) : undefined
}
</script>
